<template>
  <div class="mt-4">
    <div class="my-4 d-flex align-items-center justify-content-between">
      <h1 class="page-title">{{ $t("createQRE.title") }}</h1>
      <b-button class="mr-2" pill variant="primary" @click="$router.go(-1)">
        <b-icon-arrow90deg-left class="mr-2" />
        {{ $t("back") }}
      </b-button>
    </div>

    <b-overlay :show="loading" rounded="sm">
      <b-card
        border-variant="light"
        class="shadow-sm card-rounded custom-card-padding"
      >
        <ValidationObserver ref="observer" v-slot="{ handleSubmit, invalid }">
          <b-form @submit.prevent="handleSubmit(createQRE)">
            <!-- QRE Name * -->
            <ValidationProvider
              v-slot="{ errors, valid }"
              name="qre"
              rules="required"
            >
              <b-form-group class="mb-4">
                <template v-slot:label>
                  <p class="mb-1">
                    {{ $t("createQRE.qreName") }}
                    <span class="text-danger">*</span>
                  </p>
                </template>
                <b-form-input
                  id="qre"
                  v-model="form.qre"
                  :placeholder="$t('createQRE.qreNamePlaceholder')"
                  :state="getValidationState(errors, valid)"
                  data-cy="qre-name-input"
                />

                <b-form-invalid-feedback id="live-feedback-qre">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>

            <!-- Node * -->
            <ValidationProvider
              v-slot="{ errors, valid }"
              name="node"
              rules="required"
            >
              <b-form-group class="mb-4">
                <template v-slot:label>
                  <p class="mb-1">
                    {{ $t("createQRE.node") }}
                    <span class="text-danger">*</span>
                  </p>
                </template>
                <b-form-select
                  id="node"
                  v-model="form.node"
                  :options="nodes"
                  :state="getValidationState(errors, valid)"
                  data-cy="qre-node-select"
                  @change="onChange($event)"
                />

                <b-form-invalid-feedback id="live-feedback-node">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>

            <b-form-group id="group-node" class="mb-4" label-for="image">
              <template v-slot:label>
                <p class="mb-1">
                  {{ $t("createQRE.image") }}
                  <span class="text-danger">*</span>
                </p>
              </template>

              <div class="d-flex">
                <div class="pr-2">
                  <b-form-select
                    id="image"
                    v-model="imageInputMode"
                    :disabled="!form.node"
                    :options="imageInputModeOptions"
                  />
                </div>

                <div v-if="imageInputMode === 1" class="flex-grow-1">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="Image"
                    rules="required"
                  >
                    <b-form-input
                      id="image"
                      v-model="form.imageIdExpert"
                      :disabled="!form.node"
                      :placeholder="$t('createQRE.imageIdExpertPlaceholder')"
                      :state="getValidationState(errors, valid)"
                    />
                    <b-form-invalid-feedback id="live-feedback-cores">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </ValidationProvider>
                </div>
                <div v-if="imageInputMode === 0" class="flex-grow-1">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="Image"
                    rules="required"
                  >
                    <b-form-select
                      id="node"
                      v-model="form.imageId"
                      :disabled="!form.node"
                      :options="images"
                      :state="getValidationState(errors, valid)"
                      data-cy="qre-image-normal-select"
                    />
                    <b-form-invalid-feedback id="live-feedback-cores">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </ValidationProvider>
                </div>
              </div>
            </b-form-group>

            <b-row>
              <b-col md>
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  :rules="{
                    required: true,
                    max_value:
                      resources.availableCores !== null
                        ? resources.availableCores
                        : 16,
                    numeric: true,
                    min_value: 1,
                  }"
                  name="Cores"
                >
                  <b-form-group id="group-cores" class="mb-4" label-for="cores">
                    <template v-slot:label>
                      <p class="mb-1">
                        {{ $t("createQRE.cpus") }}
                        <span class="text-danger">*</span>
                      </p>
                    </template>
                    <b-form-input
                      id="cores"
                      v-model="form.cpu"
                      :disabled="!form.node"
                      :state="getValidationState(errors, valid)"
                      data-cy="qre-cpus-expert-input"
                    >
                    </b-form-input>
                    <div
                      v-if="resources.availableCores !== null"
                      class="available-resources"
                      data-cy="available-resources-cores"
                    >
                      {{ $t("createQRE.availableCores") }}
                      <b>{{ resources.availableCores }}</b>
                    </div>
                    <b-form-invalid-feedback id="live-feedback-cores">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md>
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  :rules="{
                    required: true,
                    regex: /^[\d]+([,.][\d]+)?\s*(B|KB|MB|GB|TB)$/i,
                    check_resource_size: {
                      totalResource: resources.availableMemoryInBytes,
                      minValue: 6291456,
                    },
                  }"
                  name="Memory"
                >
                  <b-form-group
                    id="group-memory"
                    class="mb-4"
                    label-for="memory"
                  >
                    <template v-slot:label>
                      <p class="mb-1">
                        {{ $t("createQRE.memoryExpert") }}
                        <span class="text-danger">*</span>
                      </p>
                    </template>
                    <div class="d-flex">
                      <div class="pr-2 flex-grow-1">
                        <b-form-input
                          id="memory"
                          v-model="form.memory"
                          :disabled="!form.node"
                          :placeholder="$t('createQRE.unitsPlaceholderEx')"
                          :state="getValidationState(errors, valid)"
                          data-cy="qre-memory-expert-input"
                        />

                        <div
                          v-if="resources.availableMemory !== null"
                          v-b-tooltip.hover
                          v-b-tooltip.right
                          :title="
                            byteToValueFormatter(
                              resources.availableMemoryInBytes
                            )
                          "
                          class="available-resources d-inline-flex align-content-center"
                          data-cy="available-resources-memory"
                        >
                          <div>
                            {{ $t("createQRE.availableMemory") }}
                            <b>{{ resources.availableMemory }}</b>
                          </div>
                          <div>
                            <b-icon-info-circle-fill class="mx-2 warning" />
                          </div>
                        </div>

                        <b-form-invalid-feedback id="live-feedback-memory">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>

            <ValidationProvider
              v-slot="{ errors, valid, pristine }"
              :customMessages="messages"
              :rules="{
                regex: /^(\s*\d+\s*(-\s*\d+\s*)?)(,\s*(\d+\s*(-\s*\d+\s*)?))*$/,
                cpu_set_cpus: { totalCpus: resources.totalCores - 1 },
              }"
              name="cpuSetCpus"
            >
              <b-form-group
                id="group-cpuSetCpus"
                class="mb-4"
                label-for="cpuSetCpus"
              >
                <template v-slot:label>
                  <p class="mb-1">
                    {{ $t("createQRE.cpuSetCpus") }}
                  </p>
                </template>
                <b-form-input
                  id="cpuSetCpus"
                  v-model="form.cpuSetCpus"
                  :disabled="!form.node"
                  :placeholder="$t('createQRE.coresPlaceholderEx')"
                  :state="getValidationState(errors, valid, pristine)"
                  data-cy="qre-cpus-set-cpus-input"
                />

                <div
                  v-if="resources.totalCores !== null"
                  class="available-resources"
                >
                  {{ $t("createQRE.totalCores") }}
                  <b>{{ resources.totalCores }}</b>
                </div>

                <b-form-invalid-feedback id="live-feedback-cores">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>

            <b-row>
              <b-col md>
                <ValidationProvider
                  v-slot="{ errors, valid, pristine }"
                  :rules="{ max_value: 5, numeric: true, min_value: 1 }"
                  name="limit"
                >
                  <b-form-group id="group-limit" class="mb-4" label-for="limit">
                    <template v-slot:label>
                      <p class="mb-1">
                        {{ $t("createQRE.limit") }}
                      </p>
                    </template>
                    <b-form-input
                      id="limit"
                      v-model="form.limit"
                      :disabled="!form.node"
                      :state="getValidationState(errors, valid, pristine)"
                      data-cy="qre-limit-expert-input"
                    />

                    <b-form-invalid-feedback id="live-feedback-limit">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col />
            </b-row>

            <b-row>
              <b-col md>
                <ValidationProvider
                  v-slot="{ errors, valid, pristine }"
                  :rules="{
                    regex: /^[\d]+([,.][\d]+)?\s*(B|KB|MB|GB|TB)$/i,
                    check_resource_size: {
                      totalResource: resources.availableStorageInBytes,
                      minValue: 512,
                    },
                  }"
                  name="storage"
                >
                  <b-form-group
                    id="group-storage"
                    class="mb-4"
                    label-for="storage"
                  >
                    <template v-slot:label>
                      <p class="mb-0">
                        {{ $t("createQRE.storageExpert") }}
                      </p>
                    </template>
                    <div class="d-flex">
                      <div class="pr-2 flex-grow-1">
                        <b-form-input
                          id="storage"
                          v-model="form.storage"
                          :disabled="!form.node"
                          :placeholder="$t('createQRE.unitsPlaceholderEx')"
                          :state="getValidationState(errors, valid, pristine)"
                          data-cy="qre-storage-expert-input"
                        />

                        <div
                          v-if="resources.availableStorageInBytes !== null"
                          v-b-tooltip.hover
                          v-b-tooltip.right
                          :title="
                            byteToValueFormatter(
                              resources.availableStorageInBytes
                            )
                          "
                          class="available-resources d-inline-flex align-content-center"
                        >
                          <div>
                            {{ $t("createQRE.availableStorage") }}
                            <b>{{ resources.availableStorage }}</b>
                          </div>
                          <div>
                            <b-icon-info-circle-fill class="mx-2 warning" />
                          </div>
                        </div>
                        <b-form-invalid-feedback id="live-feedback-storage">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md>
                <ValidationProvider
                  v-slot="{ errors, valid, pristine }"
                  :rules="{
                    regex: /^[\d]+([,.][\d]+)?\s*(B|KB|MB|GB|TB)$/i,
                    check_resource_size: {
                      totalResource: 107374182400,
                      minValue: 1024,
                    },
                  }"
                  name="shm"
                >
                  <b-form-group id="group-shm" class="mb-4" label-for="shm">
                    <template v-slot:label>
                      <p class="mb-0">
                        {{ $t("createQRE.shm") }}
                      </p>
                    </template>
                    <div class="d-flex">
                      <div class="pr-2 flex-grow-1">
                        <b-form-input
                          id="shm"
                          v-model="form.shm"
                          :disabled="!form.node"
                          :placeholder="$t('createQRE.unitsPlaceholderEx')"
                          :state="getValidationState(errors, valid, pristine)"
                          data-cy="qre-shm-expert-input"
                        />

                        <b-form-invalid-feedback id="live-feedback-shm">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>

            <b-row>
              <b-col md>
                <b-form-group class="mb-4">
                  <template v-slot:label>
                    <p class="mb-0">
                      {{ $t("createQRE.availableGpus") }}
                    </p>
                  </template>
                  <div v-if="resources.resourcesGpus.length > 0">
                    <b-table
                      :fields="fieldsGPUs"
                      :items="resources.resourcesGpus"
                      borderless
                      class="mb-0 md card-rounded"
                      fixed
                      head-variant="light"
                      hover
                      small
                      striped
                      @row-clicked="myRowClickHandler"
                    >
                      <template #cell(id)="data">
                        <div
                          class="d-flex align-items-center justify-content-center"
                          style="height: 40px"
                        >
                          <b-form-checkbox
                            v-model="selectedGpus"
                            :value="data.value"
                            class="m-0"
                            inline
                          />
                        </div>
                      </template>

                      <template #cell(name)="data">
                        <p class="gpu-name mb-0">
                          {{ data.value }}
                        </p>
                      </template>

                      <template #cell(status)="data">
                        <GpuIcon
                          v-b-tooltip.hover
                          :color="
                            data.value === 'BROKEN' ? '#fa5c7c' : '#6FBF94'
                          "
                          :height="25"
                          :title="
                            data.value === 'BROKEN'
                              ? 'GPU is broken'
                              : 'GPU is connected'
                          "
                          :width="25"
                        />
                      </template>

                      <template #cell(empty)="data">
                        <b-button
                          v-b-tooltip.hover
                          :title="data.item.uuid"
                          pill
                          size="sm"
                          variant="light"
                          @click="copyToClipboard(data.item.uuid)"
                        >
                          <b-icon-clipboard />
                        </b-button>
                      </template>
                    </b-table>
                  </div>
                  <p v-else>
                    <b-icon-exclamation-circle class="mr-1" />
                    {{ $t("createQRE.NoAvailableGpus") }}
                  </p>
                </b-form-group>

                <b-col md />
              </b-col>
            </b-row>

            <b-alert
              v-model="showDismissibleAlert"
              dismissible
              variant="danger"
            >
              {{ alertErrorText }}
            </b-alert>

            <div class="d-flex justify-content-end">
              <b-button
                :disabled="invalid || showDismissibleAlert"
                data-cy="create-qre-button"
                pill
                type="submit"
                variant="primary"
              >
                {{ $t("createQRE.button") }}
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import {
  max_value,
  min_value,
  numeric,
  regex,
  required,
} from "vee-validate/dist/rules";
import GpuIcon from "@/components/Icons/GpuIcon.vue";
import { byteToValueFormatter, valueToByteFormatter } from "@/util/formatters";

extend("required", required);
extend("numeric", numeric);
extend("regex", {
  ...regex,
  message: "Invalid format! Only '10GB, 1.45GB or 2,723GB' accepted",
});
extend("max_value", {
  ...max_value,
  message: "Value needs to be less than {max}",
});
extend("min_value", { ...min_value, message: "Value must be > 1" });

extend("cpu_set_cpus", {
  params: ["totalCpus"],
  validate: (value, { totalCpus }) => {
    let cpuSetArray = [];
    let errorMessage = "";
    if (value !== "") {
      // check if splitCpus contains ranges
      value.split(",").forEach((cores) => {
        // if range
        if (cores.includes("-")) {
          // check if values in range are ascending
          if (parseInt(cores.split("-")[0]) >= parseInt(cores.split("-")[1])) {
            errorMessage = "The values are not ascending";
          } else {
            for (
              let i = parseInt(cores.split("-")[0]);
              i <= parseInt(cores.split("-")[1]);
              i++
            ) {
              cpuSetArray.push(i.toString());
            }
          }
        } else {
          // else just push values to the array
          cpuSetArray.push(cores);
        }
      });
    }
    // check errors
    for (let i = 0; i < cpuSetArray.length; i++) {
      if (
        // check if same el is found at 2 different indexes
        cpuSetArray.indexOf(cpuSetArray[i]) !==
        cpuSetArray.lastIndexOf(cpuSetArray[i])
      ) {
        errorMessage = "The cores list contains duplicate values";
        break;
      }
      if (parseInt(cpuSetArray[i]) > totalCpus) {
        errorMessage = "The core maximum value is exceeded";
        break;
      }
    }
    return errorMessage !== "" ? errorMessage : true;
  },
});

extend("check_resource_size", {
  params: ["totalResource", "minValue"],
  validate: (value, { totalResource, minValue }) => {
    const valueBytes = valueToByteFormatter(value);

    if (valueBytes < minValue) {
      return "Value must be > " + byteToValueFormatter(minValue, true);
    }

    if (valueBytes > totalResource) {
      return (
        "Value needs to be less than " +
        byteToValueFormatter(totalResource, true)
      );
    }
    return true;
  },
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    GpuIcon,
  },
  name: "CreateQRE",
  data() {
    return {
      form: {
        qre: "",
        node: null,
        imageId: "",
        imageIdExpert: "",
        cpu: null,
        memory: null,
        storage: null,
        shm: null,
        cpuSetCpus: "",
        limit: null,
        gpuId: "",
      },
      imageInputModeOptions: [
        {
          value: 0,
          text: "Images Name",
        },
        {
          value: 1,
          text: "Image ID",
        },
      ],

      imageInputMode: 0,
      gpusInputMode: 0,
      nodes: [],
      images: [],
      selectedGpus: [],
      resources: {
        totalCores: null,
        availableCores: null,
        availableMemory: null,
        availableMemoryInBytes: null,
        availableStorage: null,
        availableStorageInBytes: null,
        snapshotLimit: null,
        resourcesGpus: [],
      },
      messages: {
        regex:
          "Only ranges and/or lists of numbers are accepted ( accepted symbols: ' - ', ' , ' ) ",
      },
      showDismissibleAlert: false,
      alertErrorText: "",
      loading: false,
      fieldsGPUs: [
        {
          key: "id",
          label: "",
          thStyle: { width: "50px", minWidth: "50px !important" },
          tdClass: "align-middle disable-text-selection",
        },
        {
          key: "name",
          label: "Name",
          tdClass: "align-middle disable-text-selection",
        },
        {
          key: "status",
          label: "Status",
          thStyle: {
            width: "100px",
            minWidth: "100px !important",
          },
          tdClass: "align-middle disable-text-selection",
        },
        {
          key: "empty",
          label: "",
          thStyle: { width: "50px", minWidth: "50px !important" },
          tdClass: "align-middle disable-text-selection",
        },
      ],
    };
  },
  async mounted() {
    try {
      const {
        data: { content: agents },
      } = await this.$http.get(
        this.$cfg.BASE_QRE_MANAGER_VERSION_URL + "/agents"
      );

      const {
        data: { content: images },
      } = await this.$http.get(
        this.$cfg.BASE_QRE_MANAGER_VERSION_URL + "/images"
      );

      agents.forEach((el) => {
        if (el.status === "UP")
          this.nodes.push({ value: el.id, text: el.name });
      });
      images.forEach((el) => this.images.push({ value: el.id, text: el.name }));
    } catch (err) {
      if (err.response) console.log(err.response);
    }
  },
  methods: {
    byteToValueFormatter,
    valueToByteFormatter,
    myRowClickHandler(record) {
      const exists = this.selectedGpus.includes(record.id);

      if (exists) {
        this.selectedGpus = this.selectedGpus.filter((el) => el !== record.id);
      } else {
        this.selectedGpus.push(record.id);
      }
    },

    async onChange(event) {
      try {
        const { data: resources } = await this.$http.get(
          `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/agents/${event}/hardware/resources`
        );
        this.resources = {
          totalCores: resources.totalCores,
          availableCores: resources.availableCores,
          snapshotLimit: resources.snapshotLimit,
          availableMemory: byteToValueFormatter(
            resources.availableMemoryInBytes
          ),
          availableMemoryInBytes: resources.availableMemoryInBytes,
          availableStorage: byteToValueFormatter(
            resources.availableStorageInBytes
          ),
          availableStorageInBytes: resources.availableStorageInBytes,
          resourcesGpus: resources.gpuResourceResponses.filter(
            (gpu) => gpu.status !== "BROKEN"
          ),
        };
      } catch (err) {
        console.log(err);
      }
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
    },
    async createQRE() {
      this.loading = true;
      try {
        await this.$http.post(
          this.$cfg.BASE_QRE_MANAGER_VERSION_URL + "/qres",
          {
            name: this.form.qre,
            agentId: this.form.node,
            imageId:
              this.imageInputMode === 1
                ? this.form.imageIdExpert
                : this.form.imageId,
            qreHardwareResources: {
              cores: this.form.cpu,
              memoryInBytes: valueToByteFormatter(this.form.memory),
              storageInBytes: valueToByteFormatter(this.form.storage) ?? 0,
              shmInBytes: valueToByteFormatter(this.form.shm) ?? 0,
              cpuSetCpus: this.form.cpuSetCpus.replace(" ", ""),
              snapshotLimit: this.form.limit,
              selectedGpusIds: this.selectedGpus,
            },
          },
          {
            errors: {
              400: () => true,
              409: () => true,
              404: () => true,
            },
          }
        );
        this.$root.$bvToast.toast("QRE created!", {
          title: `Success`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true,
        });
        await this.$router.push("/admin/qres");
      } catch ({ response }) {
        switch (response.status) {
          case 404:
            switch (response.data.errorCode) {
              case 0:
                this.$refs.observer.setErrors({
                  node: [response.data.message],
                });
                break;
              case 1:
                this.$refs.observer.setErrors({
                  image: [response.data.message],
                  imageExpert: [response.data.message],
                });
                break;
            }
            break;

          case 409:
            switch (response.data.errorCode) {
              case 0:
                this.$refs.observer.setErrors({
                  qre: [response.data.message],
                });
                break;
              case 1:
                this.$refs.observer.setErrors({
                  cores: [response.data.message],
                });
                break;
              case 2:
                this.$refs.observer.setErrors({
                  memory: [response.data.message],
                });
                break;
              case 3:
                this.$refs.observer.setErrors({
                  storage: [response.data.message],
                });
                break;
            }
            break;

          case 400:
            if (!response.data) {
              // error in case of type mismatch
              this.$bvToast.toast("Error", {
                title: `Something went wrong`,
                toaster: "b-toaster-bottom-right",
                variant: "danger",
              });
              break;
            }

            switch (response.data.errorCode) {
              case 0:
                this.$refs.observer.setErrors({
                  qre: [response.data.message],
                });
                break;
              case 1:
                this.$refs.observer.setErrors({
                  cpuSetCpus: [response.data.message],
                });
                break;
              default:
                this.alertErrorText = response.data.message;
                this.showDismissibleAlert = true;
            }
            break;

          default:
            console.log(response);
            this.$root.$bvToast.toast(response.data.message, {
              title: `Something went wrong`,
              toaster: "b-toaster-bottom-right",
              variant: "danger",
            });
            break;
        }
      } finally {
        this.loading = false;
      }
    },

    getValidationState(errors, valid, pristine) {
      if (pristine) return null;
      return errors[0] ? false : valid ? true : null;
    },
  },
};
</script>

<style scoped>
.available-resources {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.gpus-li-group {
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  border-radius: 0.25rem;
  margin-bottom: 0.25rem;
}
</style>
